.ipad {
    position: relative;
    padding-top: calc((2931 / 2246) * 95%);
    margin: auto;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url("../img/ipad-vertical-md.png");
        background-repeat: no-repeat;
        background-size: cover;
        pointer-events: none;
        z-index: 1;
    }

    > .carousel {
        position: absolute;
        top: 3.7%;
        right: 4.3%;
        bottom: 0;
        left: 4.2%;
        background: white;

        > .carousel-inner {
            height: 100%;

            > .carousel-item {
                height: 100%;

                [class*="col-"] {
                    height: 45%;

                    + [class*="col-"] {
                        height: 55%;
                    }
                }

                .carousel-logo {
                    width: auto;
                    max-height: 30%;
                }
            }
        }
    }

    @include media-breakpoint-up(sm) {
        padding-top: calc((2931 / 2246) * 85%);

        > .carousel {
            top: 4%;

            > .carousel-inner {
                > .carousel-item {
                    .carousel-logo {
                        max-height: none;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: calc((2246 / 2931) * 59%);

        &:before {
            background-image: url("../img/ipad.png");
        }

        > .carousel {
            position: absolute;
            top: 7.4%;
            right: 3.2%;
            bottom: 0;
            left: 3.4%;
            background: white;

            > .carousel-inner {
                height: 100%;

                > .carousel-item {
                    height: 100%;

                    [class*="col-"] {
                        height: auto;

                        + [class*="col-"] {
                            height: auto;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        > .carousel {
            top: 7.4%;
            right: 3.2%;
            bottom: 0;
            left: 3.4%;
        }
    }
}

.ipad-container {
    max-width: 1140px;
}

.carousel-indicators-ipad {
    display: flex;
    padding: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    bottom: 5px;

    [data-bs-target] {
        position: relative;
        flex: 0 0 auto;
        width: 24px;
        height: 24px;
        background: none;
        margin: 0 5px;
        border: none;
        opacity: 1;

        &:before {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 10px;
            height: 10px;
            background-color: #d7d7d7;
            border-radius: 50%;
        }
    }

    .active {
        &:before {
            background-color: $gray-800;
        }
    }

    @include media-breakpoint-up(md) {
        bottom: 10px;

        [data-bs-target] {
            margin: 0 14px;

            &:before {
                width: 12px;
                height: 12px;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        flex-direction: column;
        justify-content: center;
        left: auto;
        bottom: auto;
        right: -10%;
        height: 100%;

        [data-bs-target] {
            margin: 14px 0;
        }
    }
}

.carousel-form {
    margin: 0 -0.5rem;

    .carousel-item {
        padding: 0 0.5rem;
        background-color: white;
    }
}
