:root {
    @include media-breakpoint-down(md) {
        --#{$variable-prefix}body-font-size: 15px;
    }
}

html,
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

html {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
}

main {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.font-sans-serif {
    font-family: $font-family-sans-serif;
}

.font-serif {
    font-family: $font-family-serif;
}

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

.masthead {
    font-size: 1.125rem;
    padding: 4rem 0 6.25rem;
}
