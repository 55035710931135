.breadcrumb {
    color: $gray-700;
}

.breadcrumb-item {
    display: flex;
    align-items: baseline;

    + .breadcrumb-item {
        &:before {
            content: "";
            display: block;
            background-image: url("data:image/svg+xml;utf8, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%236b6b6b%22%3E%3Cdefs%3E%3CclipPath%20id%3D%22a%22%3E%3Cpath%20d%3D%22M0%200h16v16H0z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg%20clip-path%3D%22url%28%23a%29%22%3E%3Cpath%20data-name%3D%22Path%201%22%20d%3D%22m4.625%2016-.624-.677L10.754%208%204%20.678%204.625%200l7.376%208Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
            background-repeat: no-repeat;
            background-size: contain;
            width: 0.5rem;
            height: 0.75rem;
            padding-right: 1.2rem;
        }
    }

    &.active {
        font-weight: $font-weight-bold;
    }
}
