.dropdown-btn {
    display: flex;
    align-items: center;
    background: none;
    padding: 0 1rem;
    border: 0;

    .icon {
        height: 0.8em;
    }

    strong {
        position: relative;

        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            bottom: -4px;
            width: 100%;
            border-bottom: 1px solid;
            transform: scaleX(0);
            transition: transform 0.2s;
        }
    }

    &:focus {
        box-shadow: none;
    }

    &:hover {
        strong:after {
            transform: scaleX(1);
        }
    }
}
