// Fonts
$font-family-sans-serif: "Roboto", sans-serif;
$font-family-serif: "Roboto Slab", serif;
$headings-font-family: $font-family-serif;

$font-size-base: 1rem;
$font-weight-semibold: 500;
$h1-font-size: $font-size-base * 3.125;
$h2-font-size: $font-size-base * 2.5 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.125;
$h6-font-size: $font-size-base !default;
$h7-font-size: $font-size-base * 0.9375 !default;

$font-sizes: (
    1: $h1-font-size,
    2: $h2-font-size,
    3: $h3-font-size,
    4: $h4-font-size,
    5: $h5-font-size,
    6: $h6-font-size,
    7: $h7-font-size,
);

$blockquote-font-size: $font-size-base * 1.4;
$blockquote-footer-color: inherit;
$blockquote-footer-font-size: $font-size-base * 1.125;
$blockquote-margin-y: 0;

// Spacing
$spacer: 1rem !default;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer * 0.75,
    4: $spacer,
    5: $spacer * 1.25,
    6: $spacer * 1.5,
    7: $spacer * 2,
    8: $spacer * 2.5,
    9: $spacer * 3,
    10: $spacer * 4,
    11: $spacer * 5,
    12: $spacer * 6,
    13: $spacer * 7,
    14: $spacer * 8,
    15: $spacer * 9,
);

$enable-negative-margins: true;
$enable-shadows: true;

// Colors
$gray-100: #f6f6f6;
$gray-600: #e4e3e5;
$gray-700: #6b6b6b;
$gray-800: #4a4552;
$gray-900: #191919;
$blue: #00c8e6;
$green: #add64a;
$red: #ee0004;
$teal: #5cd194;
$cyan: #ccf5fb;
$yellow: #ffde00;
$text-muted: rgba($gray-700, 0.8);

$body-color: $gray-800;
$link-color: $gray-800;
$secondary: $gray-100;
$dark: $gray-700;
$info: $yellow;
$input-placeholder-color: rgba($gray-800, 0.6);

$box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);

// Buttons
$btn-border-radius: 1.2rem;
$btn-padding-y-lg: 0.875rem;
$btn-padding-x-lg: 2.125rem;
$btn-border-radius-lg: 2rem;
$input-btn-padding-x: 1.1rem;

$dropdown-border-width: 0;
$dropdown-padding-x: 1.5rem;
$dropdown-padding-y: 1.5rem;

$breadcrumb-active-color: $gray-800;

// form
$input-padding-y: 0.75rem;
$success: $blue;
$form-label-font-weight: $font-weight-semibold;
$form-text-margin-top: 0.5rem;
$form-feedback-icon-valid-color: #02ca9a;
$form-feedback-icon-valid-color-1: "02ca9a";
$form-feedback-icon-valid: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cdefs%3E%3CclipPath%20id%3D%22a%22%3E%3Cpath%20d%3D%22M0%200h16v16H0z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg%20data-name%3D%22Artboard%20%E2%80%93%201%22%20clip-path%3D%22url%28%23a%29%22%3E%3Cpath%20data-name%3D%22Exclusion%201%22%20fill%3D%22%23#{$form-feedback-icon-valid-color-1}%22%20d%3D%22M8%2016a8.009%208.009%200%200%201-8-8%208.009%208.009%200%200%201%208-8%208.009%208.009%200%200%201%208%208%208.009%208.009%200%200%201-8%208ZM4.325%206.964%203.19%207.985l3.5%203.827%205.746-6.381-1.129-1.017-4.613%205.18-2.369-2.631Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E%0A");
$form-feedback-icon-invalid: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cdefs%3E%3CclipPath%20id%3D%22a%22%3E%3Cpath%20d%3D%22M0%200h16v16H0z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg%20data-name%3D%22Artboard%20%E2%80%93%201%22%20clip-path%3D%22url%28%23a%29%22%3E%3Cpath%20data-name%3D%22Path%201%22%20fill%3D%22%23ee0004%22%20d%3D%22M8%2016a8%208%200%201%200-8-8%208%208%200%200%200%208%208ZM6.839%203.545H9.16L8.948%2010.1H7.05l-.212-6.56ZM9.2%2011.934a1.225%201.225%200%200%201-1.2%201.2%201.194%201.194%200%201%201%200-2.387%201.219%201.219%200%200%201%201.2%201.187Z%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E%0A");
$form-check-input-checked-bg-image: url("data:image/svg+xml,%3Csvg%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014%2010%22%3E%0A%20%20%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M.727%205.892a.97.97%200%200%201%201.345-1.4l2.235%202.15a1%201%200%200%200%201.386%200L11.927.646a.97.97%200%201%201%201.345%201.399l-7.58%207.288a1%201%200%200%201-1.385%200L.727%205.892Z%22%20fill%3D%22%23fff%22%2F%3E%0A%3C%2Fsvg%3E");
$form-check-input-checked-bg-color: #0088d1;
$form-check-input-border: 2px solid rgba($gray-700, 0.5);
$form-check-input-border-radius: 2px;
$form-check-input-width: 1.125em;
$form-check-padding-start: $form-check-input-width + 0.625em;

// table
$table-th-font-weight: $font-weight-semibold;
$table-cell-vertical-align: middle;
