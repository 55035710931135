.dashboard-thumb {
    margin-bottom: 4.875rem;

    img {
        width: 100%;
        height: auto;
    }
}

.dashboard-thumb-image {
    display: block;
    position: relative;
    margin-bottom: $spacer * 2;
    border-radius: 0.25rem;
    overflow: hidden;
    transition: box-shadow 0.2s ease-in-out;

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0.25rem;
        border: 1px solid #c9c9c9;
        background: linear-gradient(0deg, rgba(39, 39, 39, 0.266) 0%, rgba(0, 0, 0, 0) 36.2%);
        z-index: 1;
    }

    &:focus,
    &:hover {
        box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.25);
    }
}

.dasboard-thumb-actions {
    z-index: 1;
}

.dashboard-thumb-status {
    font-size: $font-size-base * 0.75;
    font-weight: $font-weight-bold;
    background-color: $info;
    padding: $spacer * 0.3125 $spacer * 0.75;
    border-radius: 1.5rem;
}

.dashboard-thumb-title {
    display: inline-block;
    font-size: $font-size-base * 1.25;
    font-weight: $font-weight-bold;
    line-height: $spacer * 1.75;
    text-decoration: none;
    margin-bottom: $spacer * 0.5;

    &:focus,
    &:hover {
        text-decoration: underline;
    }
}

.dashboard-thumb-favourite {
    width: 1.5rem;
    height: 1.5rem;
    color: $yellow;
}

.dashboard-thumb-status-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: var(--bs-yellow);

    .icon {
        color: $gray-900;
        width: 18px;
        height: 16px;
    }
}

.dashboard-thumb-status-progress {
    width: 26px;
    height: 26px;

    svg {
        width: 100%;
        height: 100%;
    }
}
