.notification-bar {
    position: fixed;
    font-size: 0.9375rem;
    color: white;
    background: #007a8c;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1.5rem 0;
    z-index: $zindex-fixed;
    transition: transform 0.25s ease-in-out;

    a {
        font-weight: 500;
        color: inherit;

        &:hover {
            text-decoration: none;
        }
    }

    .container {
        display: flex;
        justify-content: center;
        position: relative;
        padding-right: calc(1.5rem + 25px);
    }

    .btn-close {
        position: absolute;
        top: 0;
        right: 0.75rem;
        bottom: 0;
        margin: auto;

        span {
            &:before,
            &:after {
                background-color: white;
            }
        }
    }
}

.body-notification {
    transition: padding 0.25s ease-in-out;
}
