.modal {
    .btn-close {
        position: absolute;
        top: 1rem;
        right: 1rem;

        @include media-breakpoint-up(md) {
            top: 1.25rem;
            right: 1.25rem;
        }
    }
}

.modal-dialog-video {
    margin: 0 1rem;
    max-width: none;

    @include media-breakpoint-up(md) {
        max-width: 80%;
        margin: 0 auto;
    }
}

.modal-dialog-form {
    max-width: 660px;

    p {
        color: $gray-700;
    }
}
