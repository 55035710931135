.nav {
    overflow: auto;
}

.nav-header {
    padding: 5px;
    min-height: 52px;
}

.nav-link {
    position: relative;
    display: flex;
    padding: 0 $spacer * 1.25;
    text-decoration: none;

    &:focus,
    &:hover,
    &.active {
        .nav-link-text:after {
            opacity: 1;
            transform: scaleX(1);
        }
    }
}

.nav-link-text {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 0.9375rem;

    &:after {
        content: "";
        position: absolute;
        display: block;
        border-bottom: 2px solid $blue;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transform: scaleX(0);
        transition: transform 0.15s ease-in-out, opacity 0.15s ease-in-out;
    }
}

.nav-link-secondary {
    position: relative;
    display: flex;
    color: $gray-900;
    padding: 0 $spacer * 1 $spacer * 1;
    text-decoration: none;
    white-space: nowrap;
    opacity: 0.7;

    &:focus,
    &:hover {
        &:not(.active) {
            opacity: 1;
        }
    }

    &.active {
        font-weight: $font-weight-bold;
        opacity: 1;

        &:after {
            content: "";
            position: absolute;
            display: block;
            border-bottom: 2px solid $blue;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    + .nav-link-secondary {
        margin-left: $spacer * 2;
    }
}
