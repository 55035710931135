.bg-dots {
    background: $gray-800 url("../img/dots.svg") center center;
    background-size: cover;
}

.dot {
    width: 46px;
    height: 46px;
    border-radius: 50%;
}

.testimonial {
    position: relative;
    background-color: $yellow;
    padding: 4rem 0;

    @include media-breakpoint-up(lg) {
        padding: 0;

        &:before {
            content: "";
            display: block;
            padding-top: calc(790px / 1440px * 100%);
        }

        > * {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
        }
    }
}

.testimonial-logos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
        flex-direction: column;
        justify-content: space-around;
        padding: 5vw 0;
    }
}

.testimonial-logo-row {
    display: inline-flex;
    align-items: center;

    @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
}

.testimonial-logo {
    position: relative;
    background-size: cover;
    background-blend-mode: multiply;
    background-color: #ffde00;

    @include media-breakpoint-down(lg) {
        top: 0 !important;
        left: 0 !important;
        bottom: 0 !important;
        right: 0 !important;
        margin: 1rem;
    }
}
