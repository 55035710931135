$alert-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": #4bd060,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
);

.alert {
    position: relative;
    display: flex;
    align-items: flex-start;
    color: $black;
    background-color: white;
    padding: 1.5rem 4rem 1.5rem 1.5rem;
    border-left: 6px solid transparent;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);

    .alert-icon {
        flex: 0 0 auto;
        color: $gray-700;
        margin-right: 1rem;
    }

    .btn-close {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 1.2rem;
        margin: auto;

        span {
            &:before,
            &:after {
                background-color: #c4c4c4;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        padding-left: 3.125rem;
    }
}

.alert-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: 2px solid #bebebe;

    .icon {
        height: 0.8125rem;
    }
}

@each $state, $value in $alert-colors {
    .alert-#{$state} {
        border-left-color: $value;
    }
}
