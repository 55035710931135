.user {
    align-self: center;
    letter-spacing: 2px;
    width: 36px;
    height: 36px;
    background-color: var(--bs-yellow);

    span {
        margin-left: 0.15em;
    }
}

.user-name {
    font-size: 0.9375rem;
    font-weight: 500;
}

.user-email {
    display: block;
    color: rgba($gray-800, 70%);
}

.header-logo {
    max-width: 82px;

    @include media-breakpoint-up(md) {
        max-width: 100%;
    }
}

.header-container {
    position: relative;
    z-index: 1;

    @include media-breakpoint-down(md) {
        min-height: 32px;
    }
}

.dropdown-menu-account {
    min-width: 260px;
}

.dropdown-menu-account-links {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    margin: 1rem 0 0.5rem;
}

.dropdown-menu-account-link-btn {
    display: inline-flex;
    align-items: center;
    font-size: 0.9375rem;
    text-decoration: none;
    padding: 1rem 0;

    .icon {
        width: 1.125rem;
        height: 1.125rem;
        margin-right: 1.25rem;
    }

    &:hover {
        text-decoration: underline;
    }
}
