.mobile-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $gray-100;
    padding: $spacer * 1.25 0;
    overflow-y: auto;
    z-index: $zindex-offcanvas;
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.3s cubic-bezier(0.56, 0.19, 0.55, 0.93);
}

.mobile-menu-btn {
    position: relative;
    width: 30px;
    height: 22px;
    padding: 0;
    border-radius: 0;

    span,
    &:before,
    &:after {
        position: absolute;
        display: block;
        background-color: $gray-700;
        width: 100%;
        height: 2px;
        left: 0;
    }

    span {
        top: 0;
        bottom: 0;
        margin: auto;
        transition: all 0.25s ease-out;
    }

    &:before,
    &:after {
        content: "";
        margin: auto;
        transition: all 0.25s ease-out;
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
    }

    &.open {
        transition: transform 0.2s ease-out;

        span {
            width: 0;
            opacity: 0;
            transform: translate3d(50%, 0, 0);
        }

        &:before,
        &:after {
            top: 0;
            bottom: 0;
        }

        &:before {
            transform: rotate(135deg);
        }

        &:after {
            transform: rotate(225deg);
        }

        &:hover {
            transform: scale(1.1) rotate(-90deg);
        }
    }

    &:active,
    &:active:focus,
    &:focus {
        box-shadow: none;
    }
}

.mobile-menu-links {
    > li {
        border-bottom: 1px solid rgba($gray-700, 0.5);

        a {
            display: block;
            padding: 2rem 0;
            text-decoration: none;

            &.active,
            &:hover,
            &:focus {
                font-weight: $font-weight-bold;
            }
        }
    }
}
